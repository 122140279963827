@import "../../data//colors.scss";

.accounts-and-access-table-row {
    &.desktop {
        width: 90% !important;
    }

    &.mobile {
        width: 100% !important;
    }
}

.accounts-and-access-table-header {
    width: 90% !important;
}

.accounts-and-access-delete-text-on-modal {
    color: $rejectButtonFocus;
}