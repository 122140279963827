.reusable-components-table-row-nominations {
    &.desktop {
        width: 95% !important;
    }

    &.mobile {
        width: 100% !important;
    }
}

.reusable-components-table-header-nominations {
    width: 95% !important;
}