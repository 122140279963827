@import "../../data/colors.scss";

@mixin icon-button-size {
    width: 2.35rem !important;
    height: auto;
}

.accept-icon-button {
    @include icon-button-size;
    background-color: $acceptButtonActive !important;
    border-radius: 25%
}

.accept-icon-button:hover {
    cursor: pointer;
    background-color: $acceptButtonHover !important;
}

.accept-icon-button:focus {
    box-shadow: none !important;
    background-color: $acceptButtonFocus !important;
}

.accept-icon-button:disabled {
    background-color: $iconButtonDisabled !important;
}

.reject-icon-button {
    @include icon-button-size;
    background-color: $rejectButtonActive !important;
    border-radius: 25%
}

.reject-icon-button:hover {
    cursor: pointer;
    background-color: $rejectButtonHover !important;
}

.reject-icon-button:focus {
    box-shadow: none !important;
    background-color: $rejectButtonFocus !important;
}

.reject-icon-button:disabled {
    background-color: $iconButtonDisabled !important;
}

.delete-icon-button {
    @include icon-button-size;
    background-color: $deleteButtonActive !important;
    border-radius: 25%
}

.delete-icon-button:hover {
    cursor: pointer;
    background-color: $deleteButtonHover !important;
}

.delete-icon-button:focus {
    box-shadow: none !important;
    background-color: $deleteButtonFocus !important;
}

.delete-icon-button:disabled {
    background-color: $iconButtonDisabled !important;
}

.edit-icon-button {
    @include icon-button-size;
    background-color: $editButtonActive !important;
    border-radius: 25%
}

.edit-icon-button:hover {
    cursor: pointer;
    background-color: $editButtonHover !important;
}

.edit-icon-button:focus {
    box-shadow: none !important;
    background-color: $editButtonFocus !important;
}

.edit-icon-button:disabled {
    background-color: $iconButtonDisabled !important;
}

.add-icon-button {
    @include icon-button-size;
    background-color: $addButtonActive !important;
    border-radius: 25%
}

.add-icon-button:hover {
    cursor: pointer;
    background-color: $addButtonHover !important;
}

.add-icon-button:focus {
    box-shadow: none !important;
    background-color: $addButtonFocus !important;
}

.add-icon-button:disabled {
    background-color: $iconButtonDisabled !important;
}

.download-icon-button {
    @include icon-button-size;
    background-color: $downloadButtonActive !important;
    border-radius: 25%
}

.download-icon-button:hover {
    cursor: pointer;
    background-color: $downloadButtonHover !important;
}

.download-icon-button:focus {
    box-shadow: none !important;
    background-color: $downloadButtonFocus !important;
}

.download-icon-button:disabled {
    background-color: $iconButtonDisabled !important;
}