@import "../../../../data/colors.scss";
@import "../../../../data/fonts.scss";

@import "../../../../../node_modules/bootstrap/scss/bootstrap";

.submit-button {
    // override bootstrap's default behavior
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.title {
    color: $black;
}

.login-main-container {
    background-color: $white;
    width: 33%;
}

.sign-up-link, .reset-password-link {
    color: $blueText;
}

a.sign-up-link:hover, a.reset-password-link:hover {
    color: $pink;
}

.login-bottom-logo {
    bottom: -8.5%;
}

.login.btn-check:focus + .login.btn, .login.btn:focus {
    box-shadow: 0px 0px 0px 4px #ef8baf !important; // 50 % lighter shadow of $pink color
}

@include media-breakpoint-down(xxl) {
    .login-main-container {
        width: 40%;
    }
}

@include media-breakpoint-down(xl) {
    .login-main-container {
        width: 50%;
    }
}

@include media-breakpoint-down(lg) {
    .login-main-container {
        width: 75%;
    }
}

@include media-breakpoint-down(sm) {
    .login-main-container {
        width: 90%;
        margin-bottom: auto;
    }

    .form-container, .welcome-back-container {
        width: 100%;
    }
}

@include media-breakpoint-up(sm) {
    .form-container, .welcome-back-container {
        width: 75%;
    }
}