.modal-body {
    background-color: #EAECF3;
}

.modal-body-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;

    color: #1F1F1F;
}

.modal-body-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #1F1F1F;
}