@import "../../data/colors.scss";

@import "../../../node_modules/bootstrap/scss/bootstrap";

main.register-confirmation {
    background-color: $mainBackground;
    overflow-x: hidden;
    height: 100%;
}

@include media-breakpoint-up(sm) {
    main.register-confirmation {
        height: 100vh;
    }
}