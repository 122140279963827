#second-tab-tab-cultural, #second-tab-tab-assessment, #second-tab-tab-evaluation, #second-tab-tab-concluding {
    color: #1F1F1F;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    &:hover,
    &:focus, &:active {
      color: white;
      background-color: #DF175F;
    }
  }

.total-count-label {
    
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  
  }

  .question-title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
  }