.ewp-settings-box {

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 40px;
    gap: 18px;


    background: #EAECF3;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.ewp-settings-title {
    /* h3 */
    
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 22.43px;
    display: flex;
    align-items: center;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.ewp-setting-type-title {


    /* h3 */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 22.43px;
    display: flex;
    align-items: center;

    /* Primary Blue/Main */
    color: #3D619C;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.ewp-settings-checkbox-label {

    /* h4 */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 15.93px;
    text-align: left;

    color: #000000;



}