@import "../../data/colors.scss";
@import "../../data/fonts.scss";

@import "../../../node_modules/bootstrap/scss/bootstrap";

main.forgot-password {
  background-color: $mainBackground;
  overflow-x: hidden;
  height: 100vh;
}

.forgot-password-mail-icon {
  top: 5rem;
}

@include media-breakpoint-between(sm, lg) {
  main.forgot-password {
    height: auto;
  }
}
