
#courses-tab-table-a, #courses-tab-table-b, #courses-tab-table-c,
#changes-tab-final-table-a, #changes-tab-final-table-b, #changes-tab-final-table-c,
#receiving-hei-tab-home, #sending-hei-tab-home, #tors, #tor-tab-tor-table {
    background: #525862;
    color: white;
    cursor: default;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1rem !important;
}
#second-tab-tab-general-info, #second-tab-tab-courses, #second-tab-tab-changes, #second-tab-tab-tors {
    color: #1F1F1F;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    &:hover,
    &:focus, &:active {
      color: white;
      background-color: #DF175F;
    }
}


.tor-table-row {
  &.desktop {
      width: 90% !important;
  }

  &.mobile {
      width: 100% !important;
  }
}

.tor-table-header {
  width: 90% !important;
}