@import "../../../../data/colors.scss";
@import "../../../../data/fonts.scss";

@import "../../../../../node_modules/bootstrap/scss/bootstrap";

@include media-breakpoint-down(sm) {
  .forgot-password-container {
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .forgot-password-container {
    width: 75%;
  }
}
