.erasmus-app-card {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    border: none !important;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    border-radius: 10px;
}

.search {
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    background-color: white;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}