.reusable-components-table-row {
    &.desktop {
        width: 85% !important;
    }

    &.mobile {
        width: 100% !important;
    }
}

.reusable-components-table-header {
    width: 85% !important;
}