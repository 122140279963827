@import "../../../../data/colors.scss";
@import "../../../../data/fonts.scss";

@import "../../../../../node_modules/bootstrap/scss/bootstrap";

.register-text {
    @include raleway-medium;
}

.register-label {
    @include raleway-semibold;
}

.already-have-account {
    @include roboto-regular;
}

.title {
    color: $black;
}

.register-main-container {
    background-color: $white;
    width: 45%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 10rem;
}

a.confirm-dashboard-terms-link, a.read-privacy-policy-link {
    color: $blueText;
    @include roboto-semibold
}

a.confirm-dashboard-terms-link:hover, a.read-privacy-policy-link:hover {
    color: $pink;
    @include roboto-semibold
}

span.confirm-dashboard-terms-link, span.read-privacy-policy-link {
    color: $blueText;
    @include roboto-semibold;
    cursor: pointer;
}

span.confirm-dashboard-terms-link:hover, span.read-privacy-policy-link:hover {
    color: $pink;
    @include roboto-semibold
}

.login-link {
    color: $blueText;
    @include roboto-semibold
}

a.login-link:hover {
    color: $pink;
}

.was-validated .form-check-input:valid~.form-check-label a, .was-validated .form-check-input:invalid~.form-check-label a {
    color: inherit;
}

.register-button {
    background-color: $pink !important;
    border-color: $pink !important;
    // override bootstrap's default behavior
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.register-bottom-logo {
    bottom: -5%;
}

.register.btn-check:focus + .register.btn, .register.btn:focus {
    box-shadow: 0px 0px 0px 4px #ef8baf !important; // 50 % lighter shadow of $pink color
}

@include media-breakpoint-down(xxl) {
    .register-main-container {
        width: 50%;
    }
}

@include media-breakpoint-down(xl) {
    .register-main-container {
        width: 60%;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

@include media-breakpoint-down(lg) {
    .register-main-container {
        width: 90%;
        margin-top: 2.5rem;
    }
}

@include media-breakpoint-down(md) {
    .register-bottom-logo {
        bottom: -4%;
    }
}

@include media-breakpoint-down(sm) {
    .register-bottom-logo {
        bottom: -3.5%;
    }
}
