.arrow-cond {
    width: 31px;
    height: 26px;
    display: block;
    background: url('../../../../assets/arrow-cond.svg')
      no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    
  
  }

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -9px;
}