.h3-upload {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 22.43px;
    line-height: 26px;
    color: #000000;
}

.body-upload {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

.checkbox-upload {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;
    width: 1457px;
    height: 43px;
}

.upload-results{
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    font-family: 'Raleway';
    font-style: normal;
    font-size: 1rem !important;
}

.upload-subtitle{
    font-style: italic;
    font-family: 'Raleway';

}