.flex-center {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#switch1 {
    width: 52px;
    height: 26px;
    cursor: pointer;
}

#accordion-requirements-active .accordion-button:not(.collapsed) {
    
      color: white !important;
      background-color: #3D619C !important;
    
}

#accordion-requirements-active .accordion-button {
    
    color: white !important;
    background-color: #3D619C !important;
  
}

#accordion-requirements-active .accordion-button::after {
    filter: invert(95%) sepia(7%) saturate(0%) hue-rotate(333deg) brightness(124%) contrast(100%);
}

#accordion-requirements-active .accordion-button:not(.collapsed)::after {
    filter: invert(95%) sepia(7%) saturate(0%) hue-rotate(333deg) brightness(124%) contrast(100%);
}