@import "~bootstrap/scss/bootstrap";
@import 'react-bootstrap-sidebar-menu/dist/sidebar-menu.scss';
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import "./data/colors.scss";
@import "./data/constants.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Raleway', 'Roboto', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: $mainBackground !important;
}

html { 
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 ::-webkit-scrollbar {
  width: 0.6rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background-color: $black;
  outline: 1px solid $black;
  border-radius: 10px;
}

.h1-style {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 2.027rem !important;
}

.h2-style {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.802rem !important;
}

.h3-style {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.602rem !important;
}

.h4-style {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.424rem !important;
}

.h5-style {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.266rem !important;
}

.h6-style {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
}

.label-style, .form-label {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.body, .form-control, .form-select, .form-check-label, .chip {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.form-control, .form-select {
  border-width: $formElementBorderSize !important;
  border-radius: $formElementBorderRadius !important;
  border-color: $defaultBorderColor !important;
}

.form-control:focus {
  border-color: $blueText !important;
}

.form-select:focus {
  border-color: $blueText !important;
  box-shadow: 0 0 0 $formElementshadowSpread $blueShadow !important;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: $red !important;
  box-shadow: none !important;
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: $green !important;
  box-shadow: none !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: $red !important;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: $green !important;
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: $red !important;
  box-shadow: none !important;
}

.form-check-input {
  box-shadow: none !important;
}

.form-check-input:checked {
    background-color: $blueText !important;
}

.form-check-input:checked, .form-check-input:focus {
    border-color: $blueText !important; 
}

textarea.form-control:not([class*="is-valid"]):not([class*="is-invalid"]):disabled {
  border: none;
}

textarea.form-control.is-valid:disabled {
  border-color: $green !important;
}

textarea.form-control.is-invalid:disabled {
  border-color: $red !important;
}

.custom-multi-select__control, .custom-select__control {
  border-color: $defaultBorderColor !important;
}

.valid .custom-multi-select__control, .valid .custom-select__control {
  border-color: $validInput !important;
}

.invalid .custom-multi-select__control, .invalid .custom-select__control {
  border-color: $invalidInput !important;
}

.custom-multi-select__control--is-focused, .custom-select__control--is-focused {
  border-color: $blueText !important;
  box-shadow: 0 0 0 $formElementshadowSpread $blueShadow !important;
}

.validated .custom-multi-select__control--is-focused, .validated .custom-select__control--is-focused {
  box-shadow: none !important;
}

@include media-breakpoint-down(lg) {
  html {
    font-size: 13px;
  }
}