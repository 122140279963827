@import "../../../data/colors.scss";

.iias-listing-table-row {
    &.desktop {
        width: 90% !important;
    }

    &.mobile {
        width: 100% !important;
    }
}

.iias-listing-table-header {
    width: 90% !important;
}

.iias-listing-delete-text-on-modal {
    color: $rejectButtonFocus;
}

.iia-institution-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}