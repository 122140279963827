@import "../../data/colors.scss";
@import "../../data/fonts.scss";

.logo {
    margin-top: 50px;
    margin-bottom: 72px;
    width: 119px;
    height: 30px;
    display: block;
    background: url('../../assets/logo.svg')
      no-repeat left center;
    margin-left: 30px;
    transform: scale(1.3);
}

.logo:hover {
  cursor: pointer;
}

.logo-mobile {
  margin-left: 10px;
  width: 120px;
  height: 43px;
  display: block;
  background: url('../../assets/logo.svg')
    no-repeat left center;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 20px !important;
  height: 16px !important;
  vertical-align: middle;
  background-image: url('../../assets/menuIcon.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.logout {
  bottom: 5px;
  position: absolute;
}

.fa-sm {
    font-size:0.63em !important;
}

.arrow-out {
    width: 13px;
    height: 13px;
    display: block;
    background: url('../../assets/arrowOut.svg')
      no-repeat left center;
      filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.arrow-in {
  width: 13px;
  height: 13px;
  display: block;
  background: url('../../assets/arrowIn.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.ewpsettings {
  width: 13px;
  height: 13px;
  display: block;
  background: url('../../assets/ewpsettingsIcon.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.upload-solid {
  width: 14px;
  height: 14px;
  display: block;
  background: url('../../assets/upload-solid.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.handshake-simple-solid {
  width: 13px;
  height: 13px;
  display: block;
  background: url('../../assets/handshake-simple-solid.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.requirements-icon {
  width: 13px;
  height: 13px;
  display: block;
  background: url('../../assets/requirements.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.events-icon {
  width: 13px;
  height: 13px;
  display: block;
  background: url('../../assets/events.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.useful-info-icon {
  width: 13px;
  height: 13px;
  display: block;
  background: url('../../assets/useful-info.svg')
    no-repeat left center;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    

}

.nav-icon {
  width: 11px; 
  height: 12px; 
  margin-left: 21px; 
  margin-right: 6px;
}

.padding-0 {
  padding: 0 !important;
}

.wrapper {
  padding: 0;
  background-color: $white;
  border-right: 3px solid #DDDDDD;
  border-radius: 0px 25px 25px 0px;
}

.padding-top {
  padding-top: 0 !important;
}

.width {
  width: 180px !important;
}

.font {
  @include raleway-medium-16;
  color: #1F1F1F !important;
}

.font-menu-item {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
}

.margin-right {
  margin-right: 7px !important;
}

.padding-top {
  padding-top: 0 !important;
}

.padding-left{
  padding-left: 0 !important;
}

.wrapped {
  background-color: #F2F3F6;
  height: 100vh; 
  flex-grow: 1 !important;
}

.title-in{
  &:hover,
  &:focus,
  &:active {
    color:#DF175F !important;
    background-color: white !important;
    .arrow-out{
      filter: invert(15%) sepia(64%) saturate(7299%) hue-rotate(331deg) brightness(93%) contrast(87%);
    }
    .arrow-in{
      filter: invert(15%) sepia(64%) saturate(7299%) hue-rotate(331deg) brightness(93%) contrast(87%);
    }
    .requirements-icon{
      filter: invert(15%) sepia(64%) saturate(7299%) hue-rotate(331deg) brightness(93%) contrast(87%);
    }
  }
}

.sidebar-menu-sub-toggle {
    display: flex !important;
    justify-content: space-between;
  
    .sidebar-menu-nav-icon {
      transform: rotate(180deg);
      filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);

      &:hover,
      &:focus {
        filter: invert(100%) sepia(0%) saturate(7449%) hue-rotate(261deg) brightness(119%) contrast(89%);
      }
    }
  
    &.collapsed {
      .sidebar-menu-nav-icon {
        transform: rotate(0);
        filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg) brightness(97%) contrast(103%);    
      }
    }
    &:hover,
    &:focus {
      .sidebar-menu-nav-icon{
        filter: invert(100%) sepia(0%) saturate(7449%) hue-rotate(261deg) brightness(119%) contrast(89%);

      }
    }
}

.sidebar-menu-light {
.sidebar-menu-nav {
  .sidebar-menu-nav-link {
    color: black;

    &:hover,
    &:focus {
      color: white;
      background-color: #DF175F;
    }
  }

  .sidebar-menu-nav-link.active {
    color: black;
    background-color: white;
  }
  .sidebar-menu-sub-toggle{
    padding-left: 0;
    padding-right: 0;
      color: black;
      
    
    &:hover,
    &:focus {
      color: white !important;
      background-color: #DF175F !important;
    }
  }
}
}

.sidebar-menu-nav-title {
  padding-left: 15px !important;
}

.fa-stack[data-count]::after {
    position: absolute;
    right: 5%;
    top: 1%;
    content: attr(data-count);
    font-size: 70%;
    padding: 0.25em;
    border-radius: 100%;
    line-height: .75em;
    color: $white;
    background: $pink;
    text-align: center;
    min-width: 1.45em;
    font-weight: bold;
}

.toggle-icon {
  display: flex;
  margin-top: 10px; 
  margin-bottom: 10px
}

.border-0 {
  border: 0;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.wrapper-icons {
  justify-content: space-between; 
  width: 120px
}

.margin-top-2 {
  margin-top: 2px
}

.offcanvas-mobile {
  background-color: #F7F7F7; 
  width: 300px;
}

.wrapper-button {
  display: flex; 
  justify-content: center;
  margin-top: 205px
}

.button-logout {
  margin-left: 10px; 
  margin-bottom: -1px;
}

@media (min-height: 470px) {
  
  .sidebar-bottom{
    position:fixed;
    bottom:0;
  }
}