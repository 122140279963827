  .single-article-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 36px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .article img {
    max-width: 100%;
    filter: none !important;
  }