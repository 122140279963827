@import "../../data/colors.scss";
@import "../../data/fonts.scss";

@import "../../../node_modules/bootstrap/scss/bootstrap";

main.login {
    background-color: $mainBackground;
    overflow-x: hidden;
    height: 100vh;
}

.submit-button {
    background-color: $pink !important;
    border-color: $pink !important;
}

.no-more-paper {
    @include raleway-regular;
}

@include media-breakpoint-between(sm, lg) { 
    main.login {
        height: auto;
    }
}