@import "../../../node_modules/bootstrap/scss/bootstrap";

.basic-header-image-container {
    top: 5%;
    left: 2%;
    position: absolute;
}

@include media-breakpoint-down(lg) {
    .basic-header-image-container {
        position: static;
        margin-right: auto;
        margin-left: auto;
        margin-top: 1.5rem;
    }
}