@mixin raleway-medium-26 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
}

@mixin raleway-medium {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
}

@mixin roboto-regular-18 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

@mixin raleway-medium-16 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1.265rem !important;
}

@mixin raleway-medium-18 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
}

@mixin raleway-semibold-16 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

@mixin raleway-semibold {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
}

@mixin raleway-regular-13 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

@mixin raleway-regular {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
}

@mixin raleway-600-16 {
    font-family: 'Raleway' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
}

@mixin roboto-regular-16 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

@mixin roboto-regular {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
}

@mixin roboto-light {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
}

@mixin roboto-semibold {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
}

@mixin roboto-400-15 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
}

@mixin roboto-500-10 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px !important;
}

@mixin roboto-500-16 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
}

@mixin roboto-400-12 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
}