@import "../../../node_modules/bootstrap/scss/bootstrap";

.button-triplet-requests {
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.pending-requests-table-row {
    &.desktop {
        width: 85% !important;
    }

    &.mobile {
        width: 100% !important;
    }
}

.pending-requests-table-header {
    width: 85% !important;
}

@include media-breakpoint-down(lg) {
    .button-triplet-requests {
        background: none;
        box-shadow: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}