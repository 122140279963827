$pink: #DF175F;
$white: #ffffff;
$black: #1f1f1f;
$red: #E52323;
$green: #2B9436;
$greyVerticalBar: rgba(70, 73, 78, 0.5);
$greyText: #5A5A5A;
$greyFooterText: rgba(70, 73, 78, 0.6);
$mainBackground: #F2F3F6;
$blueText: #3D619C;
$passwordWeak: #E52323;
$passwordOkay: #E5AF23;
$passwordGood: #3D619C;
$passwordStrong: #63BD67;
// Icon Buttons
$iconButtonDisabled: #DBDBDB;
// Accept Icon Button
$acceptButtonActive: #3BBB48;
$acceptButtonHover: #3DDB4D;
$acceptButtonFocus: #2B9436;
// Reject Icon Button
$rejectButtonActive: #E52323;
$rejectButtonHover: #FF3737;
$rejectButtonFocus: #B61717;
// Delete Icon Button
$deleteButtonActive: #5A5A5A;
$deleteButtonHover: #696969;
$deleteButtonFocus: #1F1F1F;
// Edit Icon Button
$editButtonActive: #E5AF23;
$editButtonHover: #FFBE18;
$editButtonFocus: #BE962D;
// Add Icon Button
$addButtonActive: #73A6AD;
$addButtonHover: #8ECDD6;
$addButtonFocus: #608186;
// Download Icon Button
$downloadButtonActive: #DF175F;
$downloadButtonHover: #FE357D;
$downloadButtonFocus: #C21352;
// Form components
$blueShadow: #CBDAFC;
$defaultBorderColor: #5A5A5A;

$customTableRowOnHoverColor: #EAECF3;
$invalidInput: #dc3545;
$validInput: #198754;