@import "../../data/colors.scss";
@import "../../data/fonts.scss";

footer.basic {
    color: $greyText;
    flex-grow: 1;
    background-color: inherit;
    @include roboto-regular;
}

a.footer-bold-link {
    color: $blueText;
    @include roboto-semibold;
}

a.footer-bold-link:hover {
    color: $pink;
}

span.footer-bold-link {
    color: $blueText;
    @include roboto-semibold;
    cursor: pointer;
}

span.footer-bold-link:hover {
    color: $pink;
}