@import "../../data/colors.scss";

#tabs-inactive-tab-first-tab, 
#tabs-inactive-tab-second-tab,
#tabs-inactive-tab-third-tab,
#tabs-inactive-tab-fourth-tab,
#tabs-inactive-tab-fifth-tab, 
#tabs-inactive-tab-sixth-tab,
#tabs-inactive-tab-seventh-tab,
#tabs-inactive-tab-eigth-tab {
    background: #525862;
    color: white;
    cursor: default;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1rem !important;
}

#tabs-active-tab-first-tab, 
#tabs-active-tab-second-tab, 
#tabs-active-tab-third-tab, 
#tabs-active-tab-fourth-tab, 
#tabs-active-tab-fifth-tab, 
#tabs-active-tab-sixth-tab, 
#tabs-active-tab-seventh-tab, 
#tabs-active-tab-eigth-tab {
    color: #1F1F1F;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    background: #ffff;
    &:hover,
    &:focus, &:active {
      color: white;
      background-color: #DF175F;
    }
    border: 1px solid #EAECF3;
}

#tabs-inactive-tabpane-first-tab, 
#tabs-inactive-tabpane-second-tab,
#tabs-inactive-tabpane-third-tab, 
#tabs-inactive-tabpane-fourth-tab,
#tabs-inactive-tabpane-fifth-tab, 
#tabs-inactive-tabpane-sixth-tab,
#tabs-inactive-tabpane-seventh-tab, 
#tabs-inactive-tabpane-eigth-tab {
  background: rgb(255, 255, 255);
}

#tabs-active-tabpane-first-tab, 
#tabs-active-tabpane-second-tab, 
#tabs-active-tabpane-third-tab,
#tabs-active-tabpane-fourth-tab, 
#tabs-active-tabpane-fifth-tab, 
#tabs-active-tabpane-sixth-tab,
#tabs-active-tabpane-seventh-tab,
#tabs-active-tabpane-eigth-tab {
  background: rgb(234, 236, 243);
}