.article-container {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease; 
  cursor: pointer;
}

.article-container:hover {
  transform: scale(1.05); 
}

.slick-prev, .slick-next {
  z-index: 1;
  width: 50px;
  height: 50px;
}

.slick-prev {
  left: -32px;       
}

.slick-next {
  right: -32px;      
}

.slick-prev::before, .slick-next::before {
  font-size: 32px; 
  color:  rgba(0, 0, 0, 0.87);      
}

.slick-slide {
  user-select: none; /* Prevent text selection */
}