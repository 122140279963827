@import '../../../../data/colors';

.nav-tabs {
    border-bottom: none !important;
}


#application-info-tab-home, #student-info-tab-home, #destination-heis-tab-home {
    background: #525862;
    color: white;
    cursor: default;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1rem !important;
}

#second-tab-tab-general-info, #second-tab-tab-documents {
    color: #1F1F1F;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
    &:hover,
    &:focus, &:active {
      color: white;
      background-color: #DF175F;
    }
}

.accordion-button:not(.collapsed) {
    color: #1F1F1F  !important;
    background-color: #FFFFFF !important;
}

.accordion-button {
    color: #1F1F1F  !important;
    background-color: #FFFFFF !important;
    border: hidden !important;
    box-shadow: none !important;
    border-color: #FFF !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
  
.border-hidden{
    border-style : hidden !important;
    border : hidden !important;
}
  
.accordion-button:not(.collapsed)::after {
    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;    
}

.nominate {
    background: #27C6F9 !important;
    border-radius: 50px !important;
    border-color: #27C6F9 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 11px !important;
}

.grey {
    background: #F2F3F6;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 29px;
}

.icon-download {
    display: flex;
    justify-content: center;
    box-shadow: 0 0 2px #647693;;
    padding: 0.8em 0.6em;
    background: #647693;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    cursor: pointer;
}

.download {
    width: 81px;
    height: 14px;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 6.5px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    background: #4E4E4E;
    border-radius: 2px;
}

.download-wrap {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.button-waiting {
    margin-right: 11px; 
  }

.wrapper-waiting {
    background: #3D619C !important;
    border-radius: 50px !important;
    border-color: #3D619C !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.wrapper-reject {
    background: #E23A63 !important;
    border-radius: 50px !important;
    border-color: #E23A63 !important;
    margin-left: 20px;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.wrapper-decline {
    background: #1F1F1F !important;
    border-radius: 50px !important;
    border-color: #1F1F1F !important;
    margin-left: 20px;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.wrapper-nominate {
    background: #27C6F9 !important;
    border-radius: 50px !important;
    border-color: #27C6F9 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 11px !important;
}

.thead-font {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
}

.tbody-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

.tbody-accordion-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
}

.document-item {
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    border-radius: 2px;
    line-height: 52px;
    text-align: center;
}

.document-button {
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    border-radius: 2px 15px 15px 2px;
}

.document-icon {
    width: 21px;
    height: 28px;
    cursor: pointer;
    &:hover,
    &:focus, &:active {
      color: #27C6F9;
    }
}

.document-icon-span {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}
  
.wrapper-nominate-mobile {
    background: #3BBB48 !important;
    border:1px solid #3BBB48 !important;
    border-color: #3BBB48 !important;
    border-radius: 50px !important;
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    height: 50px;
    padding-top: 13px !important;
}

.wrapper-reject-mobile {
    background: #E23A63 !important;
    border-radius: 50px !important;
    border-color: #E23A63 !important;
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    height: 50px;
    padding-top: 13px !important;
}

.wrapper-delete-mobile {
    background: #1F1F1F !important;
    border-radius: 50px !important;
    border-color: #1F1F1F !important;
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    height: 50px;
    padding-top: 13px !important;
}