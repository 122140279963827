.group-fields-descriptions {

    /* body italic */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #1F1F1F;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.group-fields-title {

    /* h6 */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 15.75px;
    line-height: 18px;
    display: flex;
    align-items: center;

    /* Text Black */
    color: #1F1F1F;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}


#accordion-edit-factsheet-active .accordion-button:not(.collapsed) {
    
    color: white !important;
    background-color: #3D619C !important;
  
}

#accordion-edit-factsheet-active .accordion-button {
  
  color: white !important;
  background-color: #3D619C !important;

}

#accordion-edit-factsheet-active .accordion-button::after {
  filter: invert(95%) sepia(7%) saturate(0%) hue-rotate(333deg) brightness(124%) contrast(100%);
}

#accordion-edit-factsheet-active .accordion-button:not(.collapsed)::after {
  filter: invert(95%) sepia(7%) saturate(0%) hue-rotate(333deg) brightness(124%) contrast(100%);
}