 
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

$base-color: #F4F4F4;
$shine-color: rgba(229,229,229,0.8);
$animation-duration: 2s;
$avatar-offset: 52 + 16;

@keyframes shine-lines{
    0% { background-position: -100px;}
    40%, 100% {background-position: 140px;}
}

@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  }

@mixin skeleton-effect-container {
    height: 5rem;
}

@mixin skeleton-effect-item($item-width) {
    width: $item-width;
    height: 2rem;
    border-radius: 7px;
    
    @include background-gradient;
    animation: shine-lines $animation-duration infinite ease-out;
}

// Header and left side of each row styles
.table-skeleton-header div, .table-skeleton-row, .table-skeleton-buttons-row  {
    @include skeleton-effect-container;

    & div {
        @include skeleton-effect-item(5rem);
    }
}

.table-skeleton-header div div div {
    animation: none;
    background-image: none;
    float: none;
}
// ==================================

// Sizing styles for "mobile" screens
@include media-breakpoint-down(lg) {
    .table-skeleton-container .row {
        height: 2rem !important;
        width: 100% !important;
        margin-bottom: 0.5rem !important;
    }
}
// ==================================

// Total * styles
.table-skeleton-container .row {
    @include skeleton-effect-container;

    & .table-skeleton-total {
        @include skeleton-effect-item(5rem);
        float: none;
    }
}
// ==============

// Structural styles
.table-skeleton-row {
    &.desktop {
        width: 85% !important;
    }

    &.mobile {
        width: 100% !important;
        height: auto !important;
    }
}

.table-skeleton-header {
    width: 85% !important;
}
// =================