@import "../../data/colors.scss";

.fa-stack[data-count]::after {
  position: absolute;
  right: 5%;
  top: 1%;
  content: attr(data-count);
  font-size: 70%;
  padding: 0.25em;
  border-radius: 100%;
  line-height: 0.75em;
  color: $white;
  background: $pink;
  text-align: center;
  min-width: 1.45em;
  font-weight: bold;
}

.nav-bar-email-container {
  flex-grow: 2;
}

.vl {
  border-right: 3px solid $greyVerticalBar;
  height: 26px;
}

.subtitle {
  color: $greyText;
}

.title {
  color: $black;
}

.icon {
  cursor: pointer;
}

.navbar-ewp-dashboard-logo {
  width: 80%;
  margin: auto;
}

.hei-icon {
  animation: ring 7s 1.5s ease-in-out 2;
  transform-origin: 50% 0px;
}

@keyframes ring {
  0% {
    transform: rotateZ(0);
  }
  1% {
    transform: rotateZ(30deg);
  }
  3% {
    transform: rotateZ(-28deg);
  }
  5% {
    transform: rotateZ(34deg);
  }
  7% {
    transform: rotateZ(-32deg);
  }
  9% {
    transform: rotateZ(30deg);
  }
  11% {
    transform: rotateZ(-28deg);
  }
  13% {
    transform: rotateZ(26deg);
  }
  15% {
    transform: rotateZ(-24deg);
  }
  17% {
    transform: rotateZ(22deg);
  }
  19% {
    transform: rotateZ(-20deg);
  }
  21% {
    transform: rotateZ(18deg);
  }
  23% {
    transform: rotateZ(-16deg);
  }
  25% {
    transform: rotateZ(14deg);
  }
  27% {
    transform: rotateZ(-12deg);
  }
  29% {
    transform: rotateZ(10deg);
  }
  31% {
    transform: rotateZ(-8deg);
  }
  33% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  37% {
    transform: rotateZ(2deg);
  }
  39% {
    transform: rotateZ(-1deg);
  }
  41% {
    transform: rotateZ(1deg);
  }
  43% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(0);
  }
}
