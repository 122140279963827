@import "../../data/colors.scss";
@import "../../data/fonts.scss";

.apply {
  width: 159px;
  height: 39px;
  background: #3d619c !important;
  border-radius: 10px !important;
  border: 0 !important;
  @include raleway-600-16;
  color: #fefefe !important;
}

.reset {
  width: 135px;
  height: 39px;
  background: #df175f !important;
  border-radius: 10px !important;
  border: 0 !important;
  margin-left: 24px;
  @include raleway-600-16;
  color: #fefefe !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-top {
  padding-top: 0 !important;
}

#sidebar {
  width: 100% !important;
  padding-top: 25px !important;
}

.font-filter {
  @include roboto-400-15;
}

.font-search {
  @include roboto-400-15;
  color: #8b8b8b !important;
}

.font-title {
  @include raleway-medium-18;
}

.margin-right {
  margin-right: 7px !important;
}

.padding-top {
  padding-top: 0 !important;
}

.padding-left {
  padding-left: 0 !important;
}

#toggle0,
#toggle1,
#toggle2,
#toggle3,
#toggle4,
#toggle5,
#toggle6,
#toggle7,
#toggle8,
#toggle9 {
  display: flex !important;
  justify-content: space-between;
  height: 53px;

  .sidebar-menu-nav-icon {
    transform: rotate(180deg);
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg)
      brightness(97%) contrast(103%);

    &:hover,
    &:focus {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg)
        brightness(109%) contrast(102%);
    }
  }

  &.collapsed {
    .sidebar-menu-nav-icon {
      transform: rotate(0);
      filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(120deg)
        brightness(97%) contrast(103%);
    }
  }

  &:hover,
  &:focus {
    .sidebar-menu-nav-icon {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(319deg)
        brightness(109%) contrast(102%);
    }
  }
}

.sidebar-menu-light {
  .sidebar-menu-nav {
    #toggle1,
    #toggle2,
    #toggle3,
    #toggle4,
    #toggle5,
    #toggle6,
    #toggle7,
    #toggle8,
    #toggle9 {
      color: black;

      &:hover,
      &:focus {
        color: white;
        background-color: #df175f;
      }
    }

    .sidebar-menu-nav-link.active {
      color: black;
      background-color: white;
    }

    #toggle1,
    #toggle2,
    #toggle3,
    #toggle4,
    #toggle5,
    #toggle6,
    #toggle7,
    #toggle8,
    #toggle9 {
      padding-left: 0;
      padding-right: 0;
      color: black;

      &:hover,
      &:focus,
      &:active {
        background-color: #3d619c !important;
        color: white !important;
      }
    }

    #toggle0 {
      padding-left: 0;
      padding-right: 0;
      color: black;
      &:hover,
      &:focus,
      &:active {
        background-color: #73a6ad !important;
        color: white !important;
      }
    }
  }
}

.sidebar-menu-nav-title {
  padding-left: 11px;
}

.border-0 {
  border: 0;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.wrapper-icons {
  justify-content: space-between;
  width: 120px;
}

.margin-top-2 {
  margin-top: 2px;
}

.wrapper-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 25px;
}

.chip {
  background: #f2f2f2 !important;
  color: #000000 !important;
  border: 0.5px solid #dbdbdb !important;
}

img {
  filter: invert(93%) sepia(0%) saturate(485%) hue-rotate(131deg)
    brightness(95%) contrast(90%);
}

.searchWrapper {
  background: rgba(255, 255, 255, 0.65);
}

.highlightOption {
  // To change css for dropdown options
  background: #3d619c !important;
}

.option {
  @include roboto-400-12;
}
