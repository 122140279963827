@import "../../../../data/colors.scss";
@import "../../../../data/fonts.scss";
@import "../../../../data/constants.scss";

@import "../../../../../node_modules/bootstrap/scss/bootstrap";

.title {
    color: $black;
}

.check-text {
    @include raleway-medium;
}

.register-confirm-label, .register-confirm-form-label {
    @include raleway-semibold
}

.register-confirm-value, .register-confirm-roboto-regular {
    @include roboto-regular;
}

.register-confirmation-main-container {
    background-color: $white;
    width: 45%;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 10rem;
}

.input-group-focus {
    border-width: $formElementBorderSize !important;
    border-radius: $formElementBorderRadius !important;
    box-shadow: 0 0 0 $formElementshadowSpread $blueShadow !important;
    border-color: $blueText !important;
}

.show-password-button {
    cursor: pointer;
    border-left: none !important;
    border-left-width: 0px !important;
    border-left-style: none !important;
    clip-path: inset(-0.25rem -0.25rem -0.25rem 0.015rem);
    border-radius: 0px 0.375rem .375rem 0px !important;
    border-width: $formElementBorderSize !important;
    border-color: $defaultBorderColor !important;
}

.show-password-button-focus {
    box-shadow: 0 0 0 $formElementshadowSpread $blueShadow !important;
    border-color: $blueText !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    &+ .show-password-button {
        border-color: $invalidInput !important;
    }
}

.was-validated .form-control:valid, .form-control.is-valid {
    &+ .show-password-button {
        border-color: $validInput !important;
    }
}

.register-confirm-form-control {
    @include roboto-regular;
    border-right: none !important;
    border-right-width: 0px !important;
    border-right-style: none !important;
    clip-path: inset(-0.25rem 0.015rem -0.25rem -0.25rem);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.register-confirm-form-control:focus {
    box-shadow: none !important;
}

.register-confirm-progress-bar {
    height: 0.85rem !important;

    &[data-variant="danger"] {
        .progress-bar {
            background-color: $passwordWeak
        }
    }

    &[data-variant="warning"] {
        .progress-bar {
            background-color: $passwordOkay
        }
    }

    &[data-variant="info"] {
        .progress-bar {
            background-color: $passwordGood;
        }
    }

    &[data-variant="success"] {
        .progress-bar {
            background-color: $passwordStrong;
        }
    }
}

a.register-confirm-read-privacy-policy-link {
    color: $blueText;
    @include roboto-semibold
}

a.register-confirm-read-privacy-policy-link:hover {
    color: $pink;
}

.register-confirm-bottom-logo {
    bottom: -3.5%;
}

.register-confirmation-button {
    background-color: $pink !important;
    border-color: $pink !important;
    // override bootstrap's default behavior
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.register-confirmation.btn-check:focus + .register-confirmation.btn, .register-confirmation.btn:focus {
    box-shadow: 0px 0px 0px 4px #ef8baf !important; // 50 % lighter shadow of $pink color
}

@include media-breakpoint-down(xxl) {
    .register-confirmation-main-container {
        width: 50%;
    }
}

@include media-breakpoint-down(xl) {
    .register-confirmation-main-container {
        width: 60%;
    }
}

@include media-breakpoint-down(lg) {
    .register-confirmation-main-container {
        width: 85%;
        padding-left: 2.25rem !important;
        padding-right: 2.25rem !important;
        margin-top: 2.5rem;
    }

    .register-confirm-bottom-logo {
        bottom: -4%;
    }
}

@include media-breakpoint-down(sm) {
    .register-confirmation-main-container {
        width: 92.5%;
    }

    .register-confirm-bottom-logo {
        bottom: -3.75%;
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}
  
@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
}
  
.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}