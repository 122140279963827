.ghost-icon {
    width: 39px;
    height: 53px;
    background: url('../../assets/ghost-dashboard.svg')
      no-repeat left center;  
    margin-left: 15px;
  }

  .knowledge-icon {
    width: 310px;
    height: 210px;
    background: url('../../assets/knowledge-base-button.svg')
      no-repeat left center;  
    margin-left: 8px;
    cursor: pointer;
    &:hover {
        background: url('../../assets/knowledge-base-button-hover.svg')
      no-repeat left center; 
    }
  }

  .service-desk-icon {
    width: 310px;
    height: 210px;
    background: url('../../assets/service-desk-button.svg')
      no-repeat left center;  
    margin-left: 8px;
    cursor: pointer;
    &:hover {
        background: url('../../assets/service-desk-button-hover.svg')
      no-repeat left center; 
    }
  }

  .survey-icon {
    width: 310px;
    height: 210px;
    background: url('../../assets/survey-button.svg')
      no-repeat left center;  
    margin-left: 8px;
    cursor: pointer;
    &:hover {
        background: url('../../assets/survey-button-hover.svg')
      no-repeat left center; 
    }
  }
  