@mixin medium-button {
    padding : 10px 20px !important;
    border-radius: 25px !important;
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 1.142rem !important;
    letter-spacing: 0.128rem;
}


#small {
    border-radius: 20px !important;
    font-size: 14px !important;
    padding : 8px 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
}

.primary-blue {
    @include medium-button;
    background: #3D619C !important;
    border:1px solid #3D619C !important;
    border-color: #3D619C !important;
    &:hover {
        background: #5385D8 !important;
        border: 1px solid #5385D8 !important;
        border-color: #5385D8 !important;
    }
    &:active { 
        background: #2E4975 !important;
        border: 1px solid #2E4975 !important;
        border-color: #2E4975 !important;
    }
}

.primary-blue-disabled {
    @include medium-button;
    background: #83B7E5 !important;
    border-color: #83B7E5 !important;
}

.with-icon {
    margin-right: 16px;
}

#smallIcon {
    margin-right: 11px;
}

.secondary {
    @include medium-button;
    background: #E9E9E9 !important;
    border:1px solid #E9E9E9 !important;
    border-color: #E9E9E9 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    &:hover {
        background: #D9D9D9 !important;
        border: 1px solid #D9D9D9 !important;
        border-color: #D9D9D9 !important;
    }
    &:active { 
        background: #F2F3F6 !important;
        border: 1px solid #F2F3F6 !important;
        border-color: #F2F3F6 !important;
    }
}

.secondary-disabled {
    @include medium-button;
    background: #F9F9F9 !important;
    border-color: #F9F9F9 !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.outlined {
    @include medium-button;
    background-color: transparent !important;
    border:1px solid #C2C2C2 !important;
    border-color: #C2C2C2 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    &:hover {
        background: transparent !important;
        border: 1px solid #C2C2C2 !important;
        border-color: #C2C2C2 !important;
    }
    &:active { 
        background: transparent !important;
        border: 1px solid #C2C2C2 !important;
        border-color: #C2C2C2 !important;
    }
}

.outlined-disabled {
    @include medium-button;
    background: white !important;
    border-color: #C2C2C2 !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.accept {
    @include medium-button;
    background: #3BBB48 !important;
    border:1px solid #3BBB48 !important;
    border-color: #3BBB48 !important;
    &:hover {
        background: #3DDB4D !important;
        border: 1px solid #3DDB4D !important;
        border-color: #3DDB4D !important;
    }
    &:active { 
        background: #2B9436 !important;
        border: 1px solid #2B9436 !important;
        border-color: #2B9436 !important;
    }
}

.accept-disabled {
    @include medium-button;
    background: #DDDDDD !important;
    border-color: #DDDDDD !important;
}

.decline {
    @include medium-button;
    background: #E62323 !important;
    border:1px solid #E62323 !important;
    border-color: #E62323 !important;
    &:hover {
        background: #FF3737 !important;
        border: 1px solid #FF3737 !important;
        border-color: #FF3737 !important;
    }
    &:active { 
        background: #B61717 !important;
        border: 1px solid #B61717 !important;
        border-color: #B61717 !important;
    }
}

.decline-disabled {
    @include medium-button;
    background: #DDDDDD !important;
    border-color: #DDDDDD !important;
}

.orange {
    @include medium-button;
    background: #E6AF23 !important;
    border:1px solid #E6AF23 !important;
    border-color: #E6AF23 !important;
    &:hover {
        background: #FFBE18 !important;
        border: 1px solid #FFBE18 !important;
        border-color: #FFBE18 !important;
    }
    &:active { 
        background: #BE962D !important;
        border: 1px solid #BE962D !important;
        border-color: #BE962D !important;
    }
}

.orange-disabled {
    @include medium-button;
    background: #DDDDDD !important;
    border-color: #DDDDDD !important;
}

.delete {
    @include medium-button;
    background: #5A5A5A !important;
    border:1px solid #5A5A5A !important;
    border-color: #5A5A5A !important;
    &:hover {
        background: #ACACAC !important;
        border: 1px solid #ACACAC !important;
        border-color: #ACACAC !important;
    }
    &:active { 
        background: #ACACAC !important;
        border: 1px solid #ACACAC !important;
        border-color: #ACACAC !important;
    }
}

.delete-disabled {
    @include medium-button;
    background: #DDDDDD !important;
    border-color: #DDDDDD !important;
}