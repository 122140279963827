#accordion-iias-active .accordion-button:not(.collapsed) {
    
    color: white !important;
    background-color: #3D619C !important;
  
}

#accordion-iias-active .accordion-button:not(.collapsed)::after {
  filter: invert(95%) sepia(7%) saturate(0%) hue-rotate(333deg) brightness(124%) contrast(100%);
}

#second-tab-tab-conditions, #second-tab-tab-partner {
  color: #1F1F1F;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  &:hover,
  &:focus, &:active {
    color: white;
    background-color: #DF175F;
  }
}

.blue-text {
  color: #3D619C;
  &:active {
    color: white !important;
    background-color: #3D619C !important;
  }
}

.red-text {
  color: #DF175F;
  &:active {
    color: white !important;
    background-color: #3D619C !important;
  }
}

#accordion-iias-active .accordion-button:not(.collapsed) .blue-text{
    
  color: white !important;
  background-color: #3D619C !important;

}

#accordion-iias-active .accordion-button:not(.collapsed) .red-text{
    
  color: white !important;
  background-color: #3D619C !important;

}

.dashboard-ewp-icon {
  width: 116px;
  height: 39px;
  background: url('../../../assets/dashboard_ewp_communication.svg')
    no-repeat left center;  
  display: block;
  float: right;
}

.dashboard-dash-icon {
  width: 116px;
  height: 39px;
  background: url('../../../assets/dashboard_ewp_communication-01.svg')
    no-repeat left center;  
  display: block;
  float: right;
}

.pdf-icon-button {
  float: right;
  margin-right: 40px;
  margin-top: -15px;
}
