@import "../../data/colors.scss";

footer {
    flex-shrink: 0 !important; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    color: $greyFooterText;
    width: 100% !important;
    // margin-left: 12px;
    // margin-right: 12px;
    padding: 20px;
    background-color: $mainBackground;
  }

.page-container {
    min-height: 100vh;
    background-color: $mainBackground;
    flex: 1 0 auto;
    padding-left: 0;
    padding-right: 0;
}