@import "../../data/colors.scss";

@import "../../../node_modules/bootstrap/scss/bootstrap";

@import "./mixins.scss";

.main-background {
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(173, 173, 173, 0.25);
    border-radius: 2px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #1F1F1F;
}

li.active.pagination-item span {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 14px;
    gap: 10px;

    width: 36px;
    height: 40px;

    background: #3D619C !important;
    border: 1px solid #DEE2E6;
    color: white !important;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

}

li.pagination-item .page-link {
    /* body */
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 14px;
    gap: 10px;
    width: 36px;
    height: 40px;
    /* /Gray / White */
    background: #FFFFFF !important;
    color: black !important;
    /* Stroke/light */
    border: 1px solid #DEE2E6;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

li.pagination-item-prev-next.disabled-pagination-item .page-link {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 14px;
    gap: 10px;

    width: 56px;
    height: 40px;

    /* /Gray / White */
    background: #FFFFFF;

    /* Stroke/light */
    border: 1px solid #DEE2E6;
    border-radius: 4px 0px 0px 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


    /* body */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* Secondary/Light Color */
    color: #ABB5BE;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

li.pagination-item-prev-next .page-link {

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 14px;
    gap: 10px;

    width: 56px;
    height: 40px;

    /* /Gray / White */
    background: #FFFFFF;

    /* Stroke/light */
    border: 1px solid #DEE2E6;
    border-radius: 4px 0px 0px 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


    /* body */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* Secondary/Light Color */
    color: black;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.action-act {
    border-left: 3px solid $passwordOkay;
}

.action-wait {
    border-left: 3px solid #27C6F9;
}

.action-complete {
    border-left: 3px solid $passwordStrong;
}

.action-requests-pending {
    border-left: 3px solid $passwordOkay;
}

.action-requests-accepted {
    border-left: 3px solid $passwordStrong;
}

.action-requests-declined {
    border-left: 3px solid $passwordWeak
}

.action-waiting-list {
    border-left: 3px solid #27C6F9;
}

.action-cancelled {
    border-left: 3px solid #1F1F1F;
}

.action-blue {
    border-left: 3px solid $passwordGood;
}

.page-item .active {
    background: #FFFFFF;
    box-shadow: 0px 4px 3px rgba(194, 194, 194, 0.25);
}

.custom-row {
    @include custom-row;
}

.filter {
    box-sizing: border-box;
    position: absolute;
    width: 128px;
    height: 36px;
    background: #F2F3F6;
    border: 1px solid #3D619C;
    border-radius: 50px;
    font-family: 'Roboto';
    display: flex;
    align-items: center;
    color: #3D619C;
}

.table-header {
    @include table-header;
}

.table-row-with-on-click-handler:hover {
    cursor: pointer;
}

.table-title {
    display: flex;
    align-items: center;
    text-align: left;
    color: $black;
}

.hide {
    visibility: hidden;
    display: none;
}

.active-filters{
    flex-grow: 1;
    flex-basis: 0;
}