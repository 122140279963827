@import "../../../../data/colors.scss";

@import "../../../../../node_modules/bootstrap/scss/bootstrap";


@include media-breakpoint-down(sm) {
    .login-image-container {
        margin-right: auto;
        margin-left: auto;
    }
}