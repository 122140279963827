@import "../../../../data/colors.scss";
@import "../../../../data/fonts.scss";

@import "../../../../../node_modules/bootstrap/scss/bootstrap";

footer.register-footer {
    height: auto;
    min-height: 50%;
    @include roboto-regular;
}

.registration-process-text {
    width: 65%;
    color: $black;
    @include roboto-light;
}

a.footer-bold-link {
    color: $blueText;
    @include roboto-semibold;
}

a.footer-bold-link:hover {
    color: $pink;
}

@include media-breakpoint-down(sm) {
    .registration-process-text {
        margin-bottom: 3rem;
    }
}

@include media-breakpoint-down(md) {
    footer.register-footer {
        min-height: 40%;
    }
}